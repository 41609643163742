<script setup name="G2oTourStep">
import { computed, ref, useTemplateRef, watch } from 'vue';
import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/vue';

import BaseButton from '~/components/BaseButton.vue';
import G2oTourTarget from '~/components/G2oTourTarget.vue';
import Icon from '~/components/Icon.vue';

const props = defineProps({
  currentStep: {
    type: Object,
    required: true,
  },
  stepLabel: {
    type: Number,
    default: 0,
  },
  index: {
    type: Number,
    default: 0,
  },
  totalSteps: {
    type: Number,
    default: 0,
  },
  id: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: 'title',
  },
  description: {
    type: String,
    default: 'description',
  },
  offset: {
    type: Object,
    default: () => ({ mainAxis: 20, crossAxis: 0 }),
  },
  selector: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['close', 'next', 'prev']);

const targetRef = useTemplateRef('targetRef');
const tipRef = useTemplateRef('tipRef');

const targetFloating = ref(null);
const tipFloating = ref(null);
const reference = ref(null);

const stepIsActive = computed(() => props.id === props.currentStep?.id);

const stepIsHidden = computed(() => {
  if (!props.selector || !reference.value) return true;

  return props.currentStep.index > props.index;
});

const createTargetFloating = () => {
  if (targetFloating.value) {
    targetFloating.value.update();
    return;
  }

  targetFloating.value = useFloating(reference, targetRef, {
    placement: 'right',
    strategy: 'fixed',
    middleware: [offset(props.offset), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });
};

const createTipFloating = () => {
  if (tipFloating.value) {
    tipFloating.value.update();
    return;
  }
  tipFloating.value = useFloating(targetRef, tipRef, {
    placement: 'right-start',
    middleware: [offset({ mainAxis: 10, crossAxis: -10 }), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });
};

const createStep = () => {
  reference.value = document.querySelector(`[data-test="${props.selector}"]`);

  createTargetFloating();
  createTipFloating();
};

const updateStep = () => {
  tipFloating.value?.update();
};

watch(
  () => props.currentStep,
  () => {
    createStep();
  },
  {
    immediate: true,
  },
);

watch(stepIsActive, (isActive) => {
  if (!isActive) return;

  updateStep();
});
</script>

<template>
  <div>
    <G2oTourTarget
      ref="targetRef"
      :class="{ hidden: stepIsHidden, 'opacity-25': !stepIsActive }"
      :style="targetFloating?.floatingStyles"
      :animated="stepIsActive"
    />
    <div
      v-if="stepIsActive"
      ref="tipRef"
      :style="tipFloating?.floatingStyles"
      class="flex h-72 w-80 flex-col rounded-lg bg-secondary-3 p-4 text-charcoal-8 shadow-md"
    >
      <button
        type="button"
        data-test="g2o-tour-step-close"
        class="absolute right-0 top-0 m-4"
        @click="emit('close')"
      >
        <Icon icon="ic:round-close" />
      </button>
      <h2 class="text-xs uppercase">
        Tip {{ stepLabel }} out of {{ totalSteps }}
      </h2>
      <p class="mt-5">
        <strong>{{ title }}</strong>
        {{ description }}
      </p>
      <div class="mt-auto flex items-center justify-between">
        <BaseButton variant="btn-tertiary" @click="emit('prev')"
          >Previous</BaseButton
        >
        <BaseButton variant="btn-secondary" @click="emit('next')" label="Next">
          <template #rightIcon>
            <Icon icon="mdi:arrow-right-thin" class="ml-4" />
          </template>
        </BaseButton>
      </div>
    </div>
  </div>
</template>
