import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

import {
  useDevFeaturesStore,
  useOrganisationsStore,
  useProfileStore,
  useSnackbarStore,
} from '~/store';

import { featureIsEnabled } from '~/features/useFeatures';

import type { IPrivateRouteMeta } from '~/types';

const NOT_FOUND_PAGE = { name: '404' };
const FORBIDDEN_PAGE = { name: '403' };

export const useBeforeResolveRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const devFeatures = useDevFeaturesStore();
  const profileStore = useProfileStore();
  const organisationsStore = useOrganisationsStore();
  const snackbar = useSnackbarStore();

  const organisationId = to.params.organisationId as string;
  const organisation = organisationsStore.getById(organisationId);

  if (snackbar.state.visible) {
    snackbar.remove();
  }

  const { requiresAuth, permission, orgFeature, devFeature, orgPermission } =
    to.meta as IPrivateRouteMeta;

  to.meta = {
    ...to.meta,
    from: {
      name: from.name,
      params: from.params,
      query: from.query,
      hash: from.hash,
    },
  };

  if (devFeature && !devFeatures.enabled(devFeature)) {
    next(NOT_FOUND_PAGE);
    return;
  }

  if (!requiresAuth) {
    next();
    return;
  }

  if (!profileStore.isAuthenticated) {
    next({
      name: 'AuthLogin',
      query: { redirect: to.fullPath },
    });
    return;
  }

  if (!organisationId) {
    next();
    return;
  }

  if (!organisation) {
    next(NOT_FOUND_PAGE);
    return;
  }
  organisationsStore.setById(organisation.id);

  if (orgFeature && !featureIsEnabled(orgFeature, organisation)) {
    next(FORBIDDEN_PAGE);
    return;
  }

  const hasOrgPermission = orgPermission?.(to);

  if (hasOrgPermission === false) {
    next(FORBIDDEN_PAGE);
    return;
  }

  if (
    !hasOrgPermission &&
    permission &&
    !profileStore.hasPermission(permission, organisation)
  ) {
    next(FORBIDDEN_PAGE);
    return;
  }

  next();
};
