import type { DirectiveBinding } from 'vue';
import {
  arrow,
  autoUpdate,
  computePosition,
  flip,
  hide,
  offset,
  shift,
} from '@floating-ui/vue';

type TooltipSizeType = 'w-max' | 'w-64';

type TooltipPlacementType = (typeof PLACEMENT)[keyof typeof PLACEMENT];

type TooltipDirectiveEl = HTMLElement & {
  _tooltip: ITooltipInstance;
};

type TooltipValueType = string | { content: string };

type TooltipPlacementModifiersType = 'bottom' | 'start' | 'top';

type TooltipModifiersType = TooltipPlacementModifiersType | 'min' | 'click';

type TooltipArgumentNodeIdType = string;

type TooltipDirectiveBinding = DirectiveBinding<
  TooltipValueType,
  TooltipModifiersType,
  TooltipArgumentNodeIdType
>;

interface ITooltipInstance {
  tooltipEl: HTMLElement | null;
  refEl: HTMLElement;
  cleanup: (() => void) | null;
  setContent: (content: string) => void;
  dispose: () => void;
  showTooltip: () => void;
  hideTooltip: () => void;
  show: () => void;
}

const ARROW_PADDING = 10;

const PLACEMENT = {
  BOTTOM_CENTER: 'bottom',
  BOTTOM_END: 'bottom-end',
  BOTTOM_START: 'bottom-start',
  TOP_CENTER: 'top',
} as const;

const showOtherTooltips = (visible: boolean) => {
  const tooltipsContainer = document.getElementById('tooltipTarget');

  if (tooltipsContainer) {
    tooltipsContainer.style.display = visible ? 'block' : 'none';
  }
};

const createElementFromHTML = (htmlString: string) => {
  const div = document.createElement('div');
  div.innerHTML = htmlString.trim();

  return div.firstElementChild as HTMLElement;
};

const createTooltipEl = (text: string, size: TooltipSizeType) =>
  createElementFromHTML(`
  <div id="tooltip" class="z-50 ${size} p-4 text-sm text-white bg-secondary-9 rounded-lg absolute top-0 left-0">
    <p>${text}</p>
    <div id="arrow" class="absolute"></div>
  </div>
  `);

const showFloating = async (
  target: HTMLElement,
  tooltip: HTMLElement,
  arrowElement: HTMLElement,
  placement: TooltipPlacementType,
) => {
  const {
    x,
    y,
    placement: computedPlacement,
    middlewareData,
  } = await computePosition(target, tooltip, {
    placement,
    middleware: [
      offset(5),
      flip(),
      shift({ rootBoundary: 'viewport' }),
      arrow({ element: arrowElement, padding: ARROW_PADDING }),
      hide(),
    ],
  });

  Object.assign(tooltip.style, {
    left: `${x}px`,
    top: `${y}px`,
    visibility: middlewareData.hide?.referenceHidden ? 'hidden' : 'visible',
    pointerEvents: middlewareData.hide?.referenceHidden ? 'none' : '',
  });

  if (middlewareData.arrow) {
    const { x: arrowX, y: arrowY, centerOffset } = middlewareData.arrow;

    Object.assign(arrowElement.style, {
      left:
        arrowX != null && centerOffset === 0
          ? `${arrowX}px`
          : `${ARROW_PADDING}px`,
      top: arrowY != null ? `${arrowY}px` : '',
    });
  }

  tooltip.setAttribute('data-floating-placement', computedPlacement);
};

function getContent(value: TooltipValueType) {
  if (typeof value === 'string') return value;

  if (value && typeof value === 'object') return value.content;

  return '';
}

function createTooltip(
  el: TooltipDirectiveEl,
  { modifiers, value, arg }: TooltipDirectiveBinding,
) {
  const size = modifiers.min ? 'w-max' : 'w-64';
  const newTooltip = createTooltipEl(getContent(value)!, size);
  const arrowElement = newTooltip.querySelector('#arrow') as HTMLElement;

  const tooltip: ITooltipInstance = {
    tooltipEl: newTooltip,
    refEl: el,
    cleanup: null,
    setContent(content: string) {
      if (!content) {
        this.dispose();
        return;
      }
      this.tooltipEl = createTooltipEl(content, size);
    },
    dispose() {
      this.refEl.removeEventListener('mouseenter', this.showTooltip);
      this.refEl.removeEventListener('mouseleave', this.hideTooltip);
      this.hideTooltip();
      this.tooltipEl = null;
    },
    showTooltip() {
      if (!this.tooltipEl) return;

      document.body.appendChild(this.tooltipEl);

      let placement: TooltipPlacementType = PLACEMENT.BOTTOM_END;

      if (modifiers.bottom) {
        placement = PLACEMENT.BOTTOM_CENTER;
      }

      if (modifiers.start) {
        placement = PLACEMENT.BOTTOM_START;
      }

      if (modifiers.top) {
        placement = PLACEMENT.TOP_CENTER;
      }

      const tooltipNode: HTMLElement | null = arg
        ? this.refEl.querySelector(`[data-test="${arg}"]`)
        : this.refEl;

      if (tooltipNode) {
        this.cleanup = autoUpdate(tooltipNode, this.tooltipEl, () =>
          showFloating(tooltipNode, this.tooltipEl!, arrowElement!, placement),
        );

        showOtherTooltips(false);
      }
    },
    hideTooltip() {
      if (this.tooltipEl && this.tooltipEl.parentNode)
        this.tooltipEl.parentNode.removeChild(this.tooltipEl);
      if (tooltip.cleanup) {
        tooltip.cleanup();
        tooltip.cleanup = null;
      }
      showOtherTooltips(true);
    },
    show() {
      if (modifiers.click) {
        this.refEl.addEventListener('dblclick', this.showTooltip.bind(this));

        this.refEl.addEventListener('keydown', (event) => {
          if (event.key === 'Enter') {
            this.showTooltip();
          }
        });

        this.refEl.addEventListener('blur', this.hideTooltip.bind(this));
      } else {
        this.refEl.addEventListener('mouseenter', this.showTooltip.bind(this));
        this.refEl.addEventListener('mouseleave', this.hideTooltip.bind(this));
      }
    },
  };

  return tooltip;
}

function destroyTooltip(el) {
  if (el._tooltip) {
    el._tooltip.dispose();
    el._tooltip = undefined;
  }
}

function beforeMount(el: TooltipDirectiveEl, binding: TooltipDirectiveBinding) {
  const content = getContent(binding.value);

  if (!content) {
    destroyTooltip(el);
    return;
  }

  let tooltip: ITooltipInstance;

  if (el._tooltip) {
    tooltip = el._tooltip;
    tooltip.setContent(content);
  } else {
    tooltip = createTooltip(el, binding);
  }

  el._tooltip = tooltip;
  tooltip.show();
}

export const Tooltip = {
  beforeMount,
  beforeUpdate(el: TooltipDirectiveEl) {
    destroyTooltip(el);
  },
  updated: beforeMount,
  unmounted(el: TooltipDirectiveEl) {
    destroyTooltip(el);
  },
};

export default Tooltip;
