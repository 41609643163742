import { ORG_BOOKINGS_TEMPLATES, ORG_SETTINGS } from '~/constants';
import { useOrganisationsStore, useProfileStore } from '~/store';

export const ORG_BOOKINGS_TEMPLATES_CONFIGS = {
  [ORG_BOOKINGS_TEMPLATES.SUZANO]: {
    showTradeSelect: true,
    isPeriodStructure: true,
    searchablePorts: false,
    showYearSelect: false,
    isDownloadTradeCheckbox: false,
    showLaycanFilter: false,
    showNominationRemoveAction: false,
    showNominationDateFilter: true,
    fileName: 'Cargo Declaration Suzano Template.xlsx',
    hasHistoryRow: true,
    columns: [
      'code.declaration',
      'type',
      'vesselVoyage',
      'trade',
      'laycanStart',
      'laycanFinish',
      'loadingPort.name',
      'dischargePorts',
      'bookingNo',
      'comment',
      'loadingPort.qty',
    ],
    nominationColumns: [
      'vesselVoyage',
      'type',
      'trade',
      'laycanStart',
      'laycanFinish',
      'eta',
      'loadingPort.name',
      'dischargePorts',
      'comment',
      'declared',
      'nominated',
    ],
    nominationVesselColumns: [
      'status.nomination',
      'vesselVoyage',
      'type',
      'trade',
      'laycanStart',
      'laycanFinish',
      'eta',
      'loadingPort.name',
      'dischargePorts',
      'comment',
      'declared',
      'nominated',
    ],
    nominationAllocationColumns: [
      'code.nomination',
      'type',
      'trade',
      'laycanStart',
      'laycanFinish',
      'loadingPort.name',
      'dischargePorts',
      'declared',
    ],
    hasCompanyInVeson: false,
    hasNominationDownloadOptions: true,
    showLastNominatedAt: false,
    countriesInNominationWidget: false,
    enableAdvancedFilters: false,
  },
  [ORG_BOOKINGS_TEMPLATES.CMPC]: {
    showTradeSelect: false,
    isPeriodStructure: false,
    searchablePorts: false,
    showYearSelect: true,
    isDownloadTradeCheckbox: false,
    fileName: 'Cargo Declaration CMPC Template.xlsx',
    hasHistoryRow: true,
    showLaycanFilter: true,
    showNominationRemoveAction: true,
    showNominationDateFilter: false,
    columns: [
      'code.declaration',
      'vesselVoyage',
      'month',
      'trade',
      'laycanStart',
      'laycanFinish',
      'loadingPort.name',
      'dischargePorts',
      'bookingNo',
      'comment',
      'loadingPort.qty',
    ],
    nominationColumns: [
      'vesselVoyage',
      'trade',
      'laycanStart',
      'laycanFinish',
      'eta',
      'loadingPort.name',
      'dischargePorts',
      'comment',
      'declared',
      'nominated',
    ],
    nominationVesselColumns: [
      'status.nomination',
      'vesselVoyage',
      'trade',
      'laycanStart',
      'laycanFinish',
      'eta',
      'loadingPort.name',
      'dischargePorts',
      'comment',
      'declared',
      'nominated',
    ],
    nominationAllocationColumns: [
      'code.nomination',
      'trade',
      'laycanStart',
      'laycanFinish',
      'loadingPort.name',
      'dischargePorts',
      'declared',
    ],
    hasCompanyInVeson: false,
    hasNominationDownloadOptions: false,
    showLastNominatedAt: true,
    countriesInNominationWidget: true,
    enableAdvancedFilters: true,
  },
  [ORG_BOOKINGS_TEMPLATES.MAIN]: {
    showTradeSelect: false,
    isPeriodStructure: false,
    searchablePorts: true,
    showYearSelect: false,
    isDownloadTradeCheckbox: true,
    fileName: 'Cargo Declaration Internal Upload Template.xlsx',
    hasHistoryRow: true,
    showLaycanFilter: true,
    showNominationRemoveAction: false,
    columns: [
      'code.declaration',
      'status',
      'vesselVoyage',
      'trade',
      'commodity',
      'laycanStart',
      'laycanFinish',
      'loadingPort.name',
      'dischargePorts',
      'bookingNo',
      'comment',
      'loadingPort.qty',
    ],
    hasCompanyInVeson: true,
    hasNominationDownloadOptions: false,
    showLastNominatedAt: false,
    countriesInNominationWidget: false,
    enableAdvancedFilters: false,
  },
} as const;

type TemplateConfigurations =
  (typeof ORG_BOOKINGS_TEMPLATES_CONFIGS)[keyof typeof ORG_BOOKINGS_TEMPLATES_CONFIGS] & {
    isInternalWithMainTemplate?: boolean;
    isExternalWithMainTemplate?: boolean;
    showNominationDateFilter?: boolean;
  };

export const useBookingTemplateConfig = (): TemplateConfigurations => {
  const profileStore = useProfileStore();
  const organisation = useOrganisationsStore()?.organisation;

  if (!organisation) {
    return ORG_BOOKINGS_TEMPLATES_CONFIGS.suzano;
  }

  const templateType =
    organisation.settings[ORG_SETTINGS.ORGANISATION_TEMPLATE_TYPE];

  const isInternalWithMainTemplate =
    profileStore.isG2OEmployee && templateType === 'main';

  const isExternalWithMainTemplate =
    profileStore.isCustomer && templateType === 'main';

  return {
    ...ORG_BOOKINGS_TEMPLATES_CONFIGS[templateType],
    isInternalWithMainTemplate,
    isExternalWithMainTemplate,
  };
};
