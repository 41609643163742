import type { LocationQueryRaw, RouteLocationNamedRaw } from 'vue-router';

import { ICONS, ORG_FEATURES } from '~/constants';

import { featureIsEnabled } from '~/features/useFeatures';

import type {
  IOrganisation,
  IOrgFeature,
  ISidebarLink,
  ISidebarLinkChild,
} from '~/types';

import { useBookingTemplateConfig } from './useBookingTemplateConfig';

interface IUseNavBarLinksParams {
  cargoNominationIsEnabled: boolean;
  waitingForBerthEnabled: boolean;
  isG2OEmployee: boolean;
  canViewUserList: boolean;
  organisation: IOrganisation | null;
}

const enabledOnly = ({ enabled }: ISidebarLink | ISidebarLinkChild): boolean =>
  enabled !== false;

export const useNavBarLinks = ({
  cargoNominationIsEnabled,
  waitingForBerthEnabled,
  isG2OEmployee,
  canViewUserList,
  organisation,
}: IUseNavBarLinksParams): ISidebarLink[] => {
  if (isG2OEmployee && !organisation) {
    return [
      {
        to: { name: 'AdminOrganisationsIndex' },
        dataTest: 'organisation-list-nav-item',
        icon: ICONS['mdi:briefcase'],
        label: 'Organisation List',
      },
      {
        to: { name: 'AdminUsersIndex' },
        dataTest: 'users-nav-item',
        icon: ICONS['mdi:account-multiple'],
        label: 'User List',
        enabled: canViewUserList,
      },
    ].filter(enabledOnly);
  }

  if (!organisation) {
    return [];
  }

  const getOrgRoute = (
    name: string,
    query?: LocationQueryRaw,
  ): RouteLocationNamedRaw => ({
    name,
    query,
    params: {
      organisationId: organisation.id,
    },
  });

  const checkFeature = (feature: IOrgFeature) =>
    featureIsEnabled(feature, organisation);

  const { isExternalWithMainTemplate } = useBookingTemplateConfig();
  return [
    {
      to: getOrgRoute('OrganisationHomeIndex'),
      dataTest: 'home-nav-item',
      icon: ICONS['mdi:home'],
      label: 'Home',
    },
    {
      to: getOrgRoute('SailingScheduleIndex'),
      dataTest: 'sailing-schedule-nav-item',
      icon: ICONS['mdi:calendar-blank'],
      label: 'Sailing Schedule',
      enabled: checkFeature(ORG_FEATURES.SAILING_SCHEDULE),
    },
    {
      to: getOrgRoute('OrganisationBookingsIndex'),
      dataTest: 'cargo-bookings-nav-item',
      icon: ICONS['mdi:calendar-tick'],
      label: 'Cargo Bookings',
      enabled:
        cargoNominationIsEnabled &&
        checkFeature(ORG_FEATURES.BOOKINGS) &&
        !isExternalWithMainTemplate,
    },
    {
      to: getOrgRoute('OrganisationShipmentsIndex', {
        status: 'upcoming',
        expanded: 'false',
        page: 1,
        per_page: 10,
      }),
      dataTest: 'shipments-nav-item-v2',
      icon: ICONS['mdi:ferry'],
      label: 'Shipments',
    },
    {
      to: getOrgRoute('OrganisationKpisIndex'),
      dataTest: 'kpis-nav-item',
      icon: ICONS['mdi:bullseye-arrow'],
      label: 'KPIs',
      enabled:
        checkFeature(ORG_FEATURES.KPIS) &&
        (isG2OEmployee || organisation.kpisCount > 0),
    },
    {
      to: getOrgRoute('OrganisationStatisticsV2'),
      dataTest: 'organisation-statistics-item',
      icon: ICONS['mdi:chart-box'],
      label: 'Statistics',
      enabled: checkFeature(ORG_FEATURES.STATISTICS_AVAILABLE),
      children: [
        {
          to: getOrgRoute('OrganisationStatisticsV2LaycanPerformance'),
          label: 'Laycan',
          enabled: checkFeature(ORG_FEATURES.STATISTICS_LAYCAN),
        },
        {
          to: getOrgRoute('OrganisationStatisticsV2TransitTime'),
          label: 'Transit Time',
          enabled: checkFeature(ORG_FEATURES.STATISTICS_TRANSIT_TIME),
        },
        {
          to: getOrgRoute('OrganisationStatisticsV2ShipmentVolumes'),
          label: 'Shipment Volumes',
          enabled: checkFeature(ORG_FEATURES.STATISTICS_SHIPMENT_VOLUMES),
        },
        {
          to: getOrgRoute('OrganisationStatisticsV2Invoices'),
          label: 'Invoices',
          enabled: checkFeature(ORG_FEATURES.STATISTICS_INVOICES),
        },
        {
          to: getOrgRoute('OrganisationStatisticsLaytime'),
          label: 'Laytime',
          enabled: checkFeature(ORG_FEATURES.STATISTICS_LAYTIME),
        },
        {
          to: getOrgRoute('OrganisationStatisticsV2Emissions'),
          label: 'Emissions',
          enabled: checkFeature(ORG_FEATURES.STATISTICS_EMISSIONS),
        },
        {
          to: getOrgRoute('OrganisationStatisticsWaitingForBerth'),
          label: 'Waiting for Berth',
          enabled:
            checkFeature(ORG_FEATURES.STATISTICS_WAITING_FOR_BERTH) &&
            waitingForBerthEnabled,
        },
        {
          to: getOrgRoute('OrganisationStatisticsV2Productivity'),
          label: 'Productivity',
          enabled: checkFeature(ORG_FEATURES.STATISTICS_PRODUCTIVITY),
        },
        {
          to: getOrgRoute('OrganisationStatisticsV2Bunkers'),
          label: 'Bunkers',
          enabled: checkFeature(ORG_FEATURES.STATISTICS_BUNKERS),
        },
        {
          to: getOrgRoute('OrganisationStatisticsV2Claims'),
          label: 'Claims',
          enabled: checkFeature(ORG_FEATURES.STATISTICS_CLAIMS),
        },
      ].filter(enabledOnly),
    },
    {
      to: getOrgRoute('OrganisationVesselsIndex'),
      dataTest: 'vessels-item',
      icon: ICONS['g2o:vessel'],
      label: 'Vessels',
      enabled: checkFeature(ORG_FEATURES.VESSELS),
    },
    {
      to: getOrgRoute('OrganisationSettingsIndex'),
      dataTest: 'organisation-nav-item',
      icon: ICONS['mdi:briefcase'],
      label: 'Organisation',
    },
  ].filter(enabledOnly);
};

export const usePublicNavBarLinks = (): ISidebarLink[] =>
  [
    {
      to: { name: 'PublicSailingScheduleIndex' },
      dataTest: 'PublicSailingScheduleIndex-item',
      icon: ICONS['mdi:calendar-blank'],
      label: 'Sailing Schedule',
    },
    {
      to: { name: 'PublicUserCargoTracker' },
      dataTest: 'cargo-tracker',
      icon: ICONS['ic:round-share-location'],
      label: 'Cargo Tracker',
    },
    {
      to: { name: 'PublicVesselsIndex' },
      dataTest: 'PublicVesselsIndex-item',
      icon: ICONS['g2o:vessel'],
      label: 'Vessels',
    },
  ].filter(enabledOnly);
