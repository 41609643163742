<script lang="ts" setup name="TableColumnNumberUnit">
import { computed } from 'vue';

import { formatNumber } from '~/features/useNumbers';
const { value, decimal = 0 } = defineProps<{
  value: number;
  decimal?: number;
  unit?: string;
}>();
const number = computed(() => formatNumber(value, decimal));
</script>
<template>
  <p>
    {{ number }}
    <span v-if="unit" class="text-charcoal-6">{{ unit }}</span>
  </p>
</template>
