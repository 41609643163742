<script setup name="G2oTourStart">
import { computed } from 'vue';

import BaseButton from '~/components/BaseButton.vue';
import SimpleCard from '~/components/Cards/SimpleCard.vue';

const props = defineProps({
  currentStep: {
    type: Object,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
});
const emit = defineEmits(['close', 'next']);

const stepIsActive = computed(() => props.id === props.currentStep?.id);
</script>

<template>
  <SimpleCard v-if="stepIsActive" class="max-w-sm space-y-4 bg-white p-6">
    <img src="/images/illustrations/start-tour.svg" alt="" class="h-24 w-24" />
    <h2 class="subtitle-1 font-title text-lg font-bold">Start the tutorial</h2>
    <p>
      We would like to show you a quick tutorial on how to navigate the app. You
      can always find the tutorial in the Support page.
    </p>
    <div class="flex items-center justify-end space-x-4">
      <BaseButton variant="btn-secondary" @click="emit('close')">
        Skip
      </BaseButton>
      <BaseButton autofocus @click="emit('next')"> Start tutorial </BaseButton>
    </div>
  </SimpleCard>
</template>
